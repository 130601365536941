.box-holder {
	font-size: 0;
	display: flex;
	border: 5px solid $blue;
	margin-bottom: 100px;

	.box {
		width: calc( 100% / 3 );
		padding: 30px;
	
		&.blue {
			background: $blue;
		}
	}

	p {
		font-size: 14px;
		color: $blue;
	}

	.hours {

		.title.small {
			margin-bottom: 20px;
		}

		p {
			font-size: 52px;
			font-weight: $semibold;
			color: $blue;
		}

		hr {
			width: 20px;
			height: 5px;
			background: $blue;
			border: none;
			margin: 20px auto;
		}
	}

	.price {

		p {
			color: $white;
		}

		.huge {
			font-size: 79px;
			line-height: 1;
			font-weight: $medium;
			margin: 40px auto 30px;
		}
	}

	.restaurant {
		background-image: url('../img/logo_2kok_blue.svg');
		width: 149px;
		height: 145px;
		display: block;
		margin: 10px auto 30px;
	}
}