@import 'normalize';
@import 'entypo';
@import 'breaker';
@import 'mixins';

// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$s: 650px;
$m: 800px;
$l: 1050px;
$xl: 1200px;

// Default content settings.
$contentWidth: 990px;
$contentMaxWidth: 90%;

// Colors
$blue: #0583FD;
$black: #000;
$white: #fff;

$border: 5px solid $blue;

@import "fonts";
@import "logo";
@import "weekly";
@import "daily";
@import "boxes";
@import "top";
@import "footer";

.wrapper {
	position: relative;
}

section.blue {
    background: $blue;
}

.row {
    display: block;
    margin: 0 auto;
    max-width: 1144px;
    padding: 20px;

    &.text-content {
	    padding-top: 90px;
	    padding-bottom: 100px;
	    width: 750px;
	    max-width: 100%;
    }
}

@include above($m) {

	.mobile {
		display: none;
	}
}

@include below($m) {

    .row {
    	padding: 20px 30px;

    	&.lp {
    		padding: 15px;
    	} 

    	&.text-content {
    		width: 100%;
    	}
    }

	.desktop {
		display: none !important;
	}
}

pre {
	text-align: left;
	font-size: 18px;
}