.page-template-page-tmp-daily-menu {
	text-align: center;

    .wrapper {
        width: $contentWidth;
        max-width: $contentMaxWidth;
        margin: 100px auto;
    }
}

.menu.daily {
	border: $border;
	border-bottom: none;
    padding: 100px;
    margin-top: 100px;
}

.dish {
	margin-bottom: 60px;

	&:last-child {
		margin-bottom: 0;
	}
	
	.title {
		margin-bottom: 20px;
	}
}