header {
    border-bottom: $border;
    background-image: url('../img/header_bg.jpg');
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    padding: 0 0 200px;

	.row {
		padding-top: 35px;

		&.xp {
			padding-top: 90px;
		}
	}

	.top {
		font-size: 14px;
		line-height: 19px;
		text-align: left;
	}

	.left {
		padding-left: 89px;
		position: relative;
		width: 250px;
		display: inline-block;

		&:before {
			content: '';
			background-image: url('../img/logo_2kok.png');
			background-size: cover;
			width: 89px;
			height: 86px;
			display: block;
			opacity: .4;
			left: -15px;
			top: -15px;
			position: absolute;
		}

		@include below($m) {
			display: none;
		}
	}

	.right {
		float: right;
	}

	a {
		display: block;
		color: $blue;
		font-weight: $semibold;
		line-height: 24px;
	}

	@include below($m) {
		padding-bottom: 120px;

		.row.xp {
			padding-top: 70px;	
		}

		.mobile-menu {
			font-size: 16px !important;
			z-index: 11;
			position: absolute;
			top: 15px;
			right: 20px;
			color: $black;

			&:after {
				content: 'Meny';
			}

			&.is-open {
				opacity: .5;
				color: $white;

				&:after {
					content: 'Stäng';
				}
			}
		}

		.right {
			display: none;
		    float: none;
		    position: fixed;
		    background: $blue;
		    top: 0;
		    right: 0;
		    bottom: 0;
		    left: 0;
		    z-index: 10;
		    padding: 40px;

			&.is-open {
		   		display: block;
			}

		    a {
		    	color: $white;
		    	line-height: 24px !important;
		    }
		}		
	}


	@include below($s) {
		padding-bottom: 50px;

		.row.xp {
			padding: 10px 35px;	
		}

		.right {
			padding: 40px 20px;
		}
	}
}