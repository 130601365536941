body.home,
body.page,
body.error404 {
    border: $border;

    .menu.weekly {
        padding: 50px 0;

        .title.secondary {
            margin: 0;
		}

		p.semibold {
			margin-top: 15px;
		}

        .row.flex {
            display: flex;

            .third {
                width: calc(33% - 24px);
                margin-left: 36px;

                &:first-child {
                    margin-left: 0;
                }
            }

            @include below($m) {

                .third {
                    width: calc(33% - 10px);
                    margin-left: 15px;
                }
            }

            @include below($s) {
                display: block;
                padding-top: 0;
                padding-bottom: 0;

                .third {
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: 20px;
                }
            }
        }

        .dish {
    		margin: 15px 0;
        }

		.cat, p {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 24px;
		}
    }

    .rosemary {
        display: none;

        @include below($s) {
            display: block;
            width: 100%;
            margin: 20px auto;
            max-width: 300px;
            display: block;
        }
    }

    .wood {
        border-top: $border;
        background: url('../img/wood_bg.jpg');
        background-size: cover;
        padding: 50px 0;

        .left {
            padding-left: 100px;
            position: relative;
            width: 250px;
            margin: 0 auto;

            &:before {
                content: '';
                background-image: url('../img/logo_2kok.png');
                background-size: cover;
                width: 89px;
                height: 86px;
                display: block;
                opacity: .4;
                left: 0;
                @include vertical-align(absolute);
            }
        }

    }
}

.old-ie {
}