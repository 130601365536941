// Font weights.
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

body {
	font-family: 'Graphik Web';
	color: $black;
	font-weight: $regular;
	font-size: 32px;
	line-height: 1.2;
}

.title {
	color: $blue;
	font-weight: $semibold;
	text-transform: uppercase;
	font-size: 26px;

	&.large {
		font-size: 56px;
		letter-spacing: 12px;
	}

	&.small {
		font-size: 16px;
		letter-spacing: 5px;
		font-weight: $medium;
	}

	&.white {
		color: $white;
	}

	&.secondary {
	    text-transform: none;
	    color: $black;
	    margin: 20px 0 30px;
	    font-size: 48px;

		&.m {
			font-size: 24px;
			margin: 10px 0;
		}
	}
}

.medium {
	font-weight: $medium;
}

.semibold {
	font-weight: $semibold;
}

.bold {
	font-weight: $bold;
}

.text-content p {
    font-size: 22px;
    line-height: 1.5;
}

@include below($m) {

    .title.secondary {
        font-size: 24px;
    }

	.m, .cat, p, a {
	    font-size: 14px !important;
	    line-height: 20px !important;
	}

	.text-content p {
	    font-size: 16px !important;
	    line-height: 1.5 !important;
	}
}

// Graphik.
@font-face {
	font-family: 'Graphik Web';
	src: url('../fonts/Graphik-Semibold-Web.eot');
	src: url('../fonts/Graphik-Semibold-Web.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Graphik-Semibold-Web.woff') format('woff');
	font-weight:  600;
	font-style:   normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Graphik Web';
	src: url('../fonts/Graphik-Medium-Web.eot');
	src: url('../fonts/Graphik-Medium-Web.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Graphik-Medium-Web.woff') format('woff');
	font-weight:  500;
	font-style:   normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Graphik Web';
	src: url('../fonts/Graphik-Regular-Web.eot');
	src: url('../fonts/Graphik-Regular-Web.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Graphik-Regular-Web.woff') format('woff');
	font-weight:  400;
	font-style:   normal;
	font-stretch: normal;
}