.logo {
    display: block;
    margin: 0 auto;
	width: 382px;
	height: 118px;
	background-image: url('../img/logo_plankan.svg');
	background-size: cover;

	&.header {
	    width: 512px;
	    height: 158px;
	}

	@include below($s) {
	    width: 210px !important;
	    height: 65px !important;
	}
}