footer.full {
	border-top: $border;
	text-align: left;

	.find-us {
		font-size: 0;
		padding: 70px 0;

		.map-holder {
			display: inline-block;
			width: 720px;
			height: 390px;
			max-width: 70%;
			background: url('../img/map.jpg');
			background-size: cover !important;
			background-position: center center;
			transition: .2s;

			&:hover {
				background: url('../img/map.jpg'), rgba(5,131,253,.2);
				background-position: center center;
				background-blend-mode: multiply;
			}
		}

		.address-holder {
			display: inline-block;
			width: 30%;
			vertical-align: top;
			padding-left: 40px;
			font-size: 19px;
			line-height: 23px;

			span {
				display: block;
			}

			p {
				margin-bottom: 30px;
				color: $black !important;
			}

			a {
				color: $black;
			}
		}

		@include below($l) {

			.address-holder {
				padding-left: 20px;
			}
		}			

		@include below($m) {
			padding: 20px 0 0;

			.map-holder {
				display: none;
			}

			.address-holder {
				padding-left: 0;
				width: 100%;

				a {
					display: inline-block;
				}

				p {
					margin-bottom: 25px;
				}
			}
		}
	}

	.mobile-map {
		width: 100%;
		height: auto;
		display: none;

		@include below($m) {
			display: block;
		}
	}

	.blue {
		position: relative;
		color: $white;
		font-size: 14px;
		line-height: 18px;
		min-height: 160px;

		.row {
			padding: 43px 15px !important;

			@include below($s) {
				padding: 70px 15px 43px !important;					
			}
		}

		a {
			color: $white;
		}
	}

	#to-top {
		position: absolute;
		top: 25px;
	}

	.box {
		display: inline-block;
		width: 33%;
		vertical-align: bottom;

		@include below($m) {
			width: 100%;
		}
	}

	.right {
		opacity: .5;
		text-align: right;

		p, a {
			display: inline-block;
		}
	}

	.lindbacks {
		background-image: url('../img/logo_lindbacks.svg');
		width: 145px;
		height: 78px;
		display: block;
		margin: 0 auto;
	}
}